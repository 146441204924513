import api from "@/api/index";

export default {
  list(credentials) {
    return api().get("nfts", credentials);
  },

  buy(credentials) {
    return api().post("nft/buy", credentials);
  },

  collectionsList(credentials) {
    return api().post("nft/collectionsList", credentials);
  },
};

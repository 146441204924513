import api from "@/api/index";

export default {
  getTransactionOneHash(credentials) {
    return api().get("one_hash/transactions", credentials);
  },
  bet_oneHash(credentials) {
    return api().post("one_hash/bet", credentials);
  },

  transactions_bet_oneHash(credentials) {
    return api().get("one_hash/bet_transactions", credentials);
  },
  bethistory(credentials) {
    return api().post("bet/history", credentials);
  },
};

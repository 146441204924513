<template>
  <div
    class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-24 lg:px-8 lg:py-20"
  >
    <div class="mx-auto max-w-screen-md">
      <h4 class="font-sans text-2xl font-bold">What is HasHBX ?</h4>

      <p class="text-gray-300 font-sans text-1xl">
        HashBX has the most users of any decentralized platform, ever. And those
        users are now entrusting the platform with over $0.1 billion in funds.
      </p>
    </div>

    <div
      class="grid gap-10 row-gap-8 mx-auto sm:row-gap-10 lg:max-w-screen-lg sm:grid-cols-2 lg:grid-cols-3 mt-10"
    >
      <div class="flex">
        <img
          class="object-cover w-20 h-20 mr-4 shadow"
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/1200px-MetaMask_Fox.svg.png"
          alt="Person"
        />
        <div class="flex flex-col justify-center">
          <p class="text-lg font-bold">Metamask</p>
          <p class="text-sm text-gray-400">BSC Chain</p>
        </div>
      </div>
      <div class="flex">
        <img
          class="object-cover w-20 h-20 mr-4 rounded-full shadow"
          src="https://play-lh.googleusercontent.com/-3uTwEsZDk2NEgRblDEfIIY7T-xAZfJPN5JzVKz7s94Ds8KrKCrSVHvkEuneJlUBekc"
          alt="Person"
        />
        <div class="flex flex-col justify-center">
          <p class="text-lg font-bold">Trust wallet</p>
          <p class="text-sm text-gray-400">BSC Chain</p>
        </div>
      </div>
      <div class="flex">
        <img
          class="object-cover w-20 h-20 mr-4 rounded-full shadow p-2"
          style="border: 2px solid white; border-radius: 50%"
          src="https://cryptologos.cc/logos/safepal-sfp-logo.png"
          alt="Person"
        />
        <div class="flex flex-col justify-center">
          <p class="text-lg font-bold">Safepal</p>
          <p class="text-sm text-gray-400">BSC Chain</p>
        </div>
      </div>
      <div class="flex">
        <img
          class="object-cover w-20 h-20 mr-4 rounded-full shadow"
          src="https://pbs.twimg.com/profile_images/1376939922423128064/Pj-UFtCq_400x400.png"
          alt="Person"
        />
        <div class="flex flex-col justify-center">
          <p class="text-lg font-bold">ALL Wallet</p>
          <p class="text-sm text-gray-400">BSC Chain</p>
        </div>
      </div>
    </div>
  </div>
</template>

<template>
  <div
    class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
  >
    <div class="max-w-lg sm:text-center sm:mx-auto">
      <h2
        class="mb-6 font-sans text-3xl font-bold tracking-tight text-gray-100 sm:text-4xl sm:leading-none"
      >
        <span class="relative inline-block">
          <svg
            viewBox="0 0 52 24"
            fill="currentColor"
            class="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-32 lg:-mt-10 sm:block"
          >
            <defs>
              <pattern
                id="6b0188f3-b7a1-4e9b-b95e-cad916bb3042"
                x="0"
                y="0"
                width=".135"
                height=".30"
              >
                <circle cx="1" cy="1" r=".7"></circle>
              </pattern>
            </defs>
            <rect
              fill="url(#6b0188f3-b7a1-4e9b-b95e-cad916bb3042)"
              width="52"
              height="24"
            ></rect>
          </svg>
        </span>
        Idle RPG
      </h2>
      <p class="text-base text-gray-300 md:text-lg">
        This game is a great way to get ahead while having fun. Play and earn
        games while fighting monsters or other players. Level up to gain
        abilities. Or as a pay-to-win player, you can earn more.
      </p>
      <hr class="my-8 border-gray-300" />
      <div class="flex items-center mb-3 sm:justify-center">
        <a
          href="https://discord.gg/rEXWTQEUrN"
          target="_blank"
          class="w-32 flex-none text-sm px-6 py-2 border rounded text-yellow-50 border-none bg-gradient-to-r from-pink-600 to-yellow-600"
          >Join Discord</a
        >
      </div>
      <p
        class="max-w-xs text-xs text-gray-400 sm:text-sm sm:max-w-sm sm:mx-auto"
      >
        It is important to maintain good community relations in order to keep
        the community happy and to ensure that the company is able to operate
        smoothly.
      </p>
    </div>
  </div>
</template>

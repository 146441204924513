<template>
  <div>
    <div class="z-0 font-sane">
      <header>
        <ConnectWallet />
      </header>

      <div class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl mt-10">
        <div class="sm:max-w-xl md:max-w-full lg:max-w-screen-2xl">
          <div
            class="px-4 sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-10 lg:px-8 lg:py-10"
          >
            <div class="flex justify-center">
              <div class="mt-10 text-center">
                <h3
                  class="text-3xl font-bold lg:text-3xl xl:text-3xl text-transparent bg-clip-text bg-gradient-to-r from-green-200 via-pink-300 to-blue-500 text-shadow-white"
                >
                  Models
                </h3>

                <h3
                  class="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-100 mt-5"
                >
                  Here are some popular generative model APIs that you can use
                  in your application.
                </h3>

                <!-- <button
                  class="w-42 flex-none text-lg text-base px-6 py-3 border rounded-xl text-gray-100 border-none bg-gradient-to-r from-pink-500 to-blue-600 font-extrabold mt-10"
                >
                  SELL PROMPT
                </button> -->

                <div>
                  <div class="flex justify-center mt-16">
                    <div
                      class="grid grid-cols-3 gap-1 lg:grid-cols-3 md:grid-cols-3 text-center"
                    >
                      <router-link
                        class="border-solid border p-2 m-1 rounded-2xl border-light-blue-500 bg-black hover:bg-blue-900 text-white"
                        to="/genAI"
                      >
                        Stable Diffusion
                        <br />
                        <b class="text-xs text-green-300">( Text to Images )</b>
                      </router-link>

                      <router-link
                        class="border-solid border p-2 m-1 rounded-2xl border-light-blue-500 bg-black hover:bg-blue-900 text-white"
                        to="/genImagetoImage"
                      >
                        Stable Diffusion
                        <br />
                        <b class="text-xs text-green-300"
                          >( Images to Images )</b
                        >
                      </router-link>

                      <router-link
                        class="border-solid border p-2 m-1 rounded-2xl border-light-blue-500 bg-black hover:bg-blue-900 text-white"
                        to="/imgRecovery"
                      >
                        Image Recovery
                        <br />
                        <b class="text-xs text-green-300"
                          >( Images to Images )</b
                        >
                      </router-link>
                    </div>
                  </div>
                </div>

                <br />
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>

      <div
        class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl mt-10 p-5"
      >
        <div class="sm:max-w-xl md:max-w-full lg:max-w-screen-2xl">
          <div class="flex">
            <div class="">
              <span class="fa fa-quote-left fa-2x text-white"></span>
            </div>
            <a
              class="ml-2 font-medium text-xl xl:text-2xl text-gray-100 transition duration-300 ease-in-out transform hover:-translate-y-1 cursor-pointer"
            >
              Models
            </a>
          </div>
          <div class="mt-10">
            <div class="">
              <div class="grid grid-cols-1 gap-4 lg:grid-cols-3">
                <router-link
                  to="/genAI"
                  class="cursor-pointer border rounded-lg"
                >
                  <div
                    class="rounded-xl bg-cover bg-center h-96 lg:h-96 w-full"
                    :style="
                      'background-image: url(' +
                      'https://cdn.midjourney.com/27a80ac5-6efc-4801-b4e4-d27ab8701eb6/0_0.png' +
                      ')'
                    "
                  >
                    <a class="flex items-end justify-center h-full w-ful">
                      <div
                        class="p-3 rounded-md bg-opacity-80 bg-gray-700 text-gray-100 w-full"
                      >
                        <h3 class="text-lg font-medium font-sans">
                          Stable Diffusion v@later
                        </h3>

                        <h3 class="text-sm text-green-400">
                          ( Text to Images )
                        </h3>

                        <h3 class="text-sm mt-2">
                          Convert Text into Images with the latest distilled
                          stable diffusion model
                        </h3>
                      </div>
                    </a>
                  </div>
                </router-link>

                <router-link
                  to="/genImagetoImage"
                  class="cursor-pointer border rounded-lg"
                >
                  <div
                    class="rounded-xl bg-cover bg-center h-96 lg:h-96 w-full"
                    :style="
                      'background-image: url(' +
                      'https://cdn.midjourney.com/23f84abd-b5d6-44bc-8b73-eca3e3c128dc/0_0.png' +
                      ')'
                    "
                  >
                    <a class="flex items-end justify-center h-full w-ful">
                      <div
                        class="p-3 rounded-md bg-opacity-80 bg-gray-700 text-gray-100 w-full"
                      >
                        <h3 class="text-lg font-medium font-sans">
                          Stable Diffusion v@later
                        </h3>

                        <h3 class="text-sm text-green-400">
                          ( Images to Images )
                        </h3>

                        <h3 class="text-sm mt-2">
                          Convert Text into Images with the latest distilled
                          stable diffusion model
                        </h3>
                      </div>
                    </a>
                  </div>
                </router-link>

                <router-link
                  to="/imgRecovery"
                  class="cursor-pointer border rounded-lg"
                >
                  <div
                    class="rounded-xl bg-cover bg-center h-96 lg:h-96 w-full"
                    :style="
                      'background-image: url(' +
                      'https://cdn.midjourney.com/7057de77-26b2-4571-89fb-468a6c32c197/0_0.png' +
                      ')'
                    "
                  >
                    <a class="flex items-end justify-center h-full w-ful">
                      <div
                        class="p-3 rounded-md bg-opacity-80 bg-gray-700 text-gray-100 w-full"
                      >
                        <h3 class="text-lg font-medium font-sans">
                          Image Recovery
                        </h3>

                        <h3 class="text-sm text-green-400">
                          ( Images to Images )
                        </h3>

                        <h3 class="text-sm mt-2">
                          Transformative technology gives your low-quality
                          visuals a stunning HD upgrade. Restore old photos to
                          incredible detail and elevate your content to a
                          professional level.
                        </h3>
                      </div>
                    </a>
                  </div>
                </router-link>

                <div class="cursor-pointer border rounded-lg">
                  <div
                    class="rounded-xl bg-cover bg-center h-96 lg:h-96 w-full"
                    :style="
                      'background-image: url(' +
                      'https://www.segmind.com/qr_output.jpeg' +
                      ')'
                    "
                  >
                    <a class="flex items-end justify-center h-full w-ful">
                      <div
                        class="p-3 rounded-md bg-opacity-80 bg-gray-700 text-gray-100 w-full"
                      >
                        <h3 class="text-lg font-medium font-sans">
                          QR Generator ( coming soon )
                        </h3>

                        <h3 class="text-sm mt-2">
                          Create beautiful and creative QR codes for your
                          marketing campaigns.
                        </h3>
                      </div>
                    </a>
                  </div>
                </div>

                <div class="cursor-pointer border rounded-lg">
                  <div
                    class="rounded-xl bg-cover bg-center h-96 lg:h-96 w-full"
                    :style="
                      'background-image: url(' +
                      'https://www.segmind.com/paragon1.jpeg' +
                      ')'
                    "
                  >
                    <a class="flex items-end justify-center h-full w-ful">
                      <div
                        class="p-3 rounded-md bg-opacity-80 bg-gray-700 text-gray-100 w-full"
                      >
                        <h3 class="text-lg font-medium font-sans">
                          Word2img ( coming soon )
                        </h3>

                        <h3 class="text-sm mt-2">
                          Create beautifully designed words using Segmind’s word
                          to image for your marketing purposes.
                        </h3>
                      </div>
                    </a>
                  </div>
                </div>

                <div class="cursor-pointer border rounded-lg">
                  <div
                    class="rounded-xl bg-cover bg-center h-96 lg:h-96 w-full"
                    :style="
                      'background-image: url(' +
                      'https://www.segmind.com/reliberate1.jpeg' +
                      ')'
                    "
                  >
                    <a class="flex items-end justify-center h-full w-ful">
                      <div
                        class="p-3 rounded-md bg-opacity-80 bg-gray-700 text-gray-100 w-full"
                      >
                        <h3 class="text-lg font-medium font-sans">
                          SD Outpainting ( coming soon )
                        </h3>

                        <h3 class="text-sm mt-2">
                          Stable Diffusion Outpainting can extend any image in
                          any direction.
                        </h3>
                      </div>
                    </a>
                  </div>
                </div>

                <div class="cursor-pointer border rounded-lg">
                  <div
                    class="rounded-xl bg-cover bg-center h-96 lg:h-96 w-full"
                    :style="
                      'background-image: url(' +
                      'https://www.segmind.com/_next/image?url=%2Fkandinsky2.2.jpeg&w=3840&q=75' +
                      ')'
                    "
                  >
                    <a class="flex items-end justify-center h-full w-ful">
                      <div
                        class="p-3 rounded-md bg-opacity-80 bg-gray-700 text-gray-100 w-full"
                      >
                        <h3 class="text-lg font-medium font-sans">
                          Kandinsky 2.2 ( coming soon )
                        </h3>

                        <h3 class="text-sm mt-2">
                          Kandinsky inherits best practicies from Dall-E 2 and
                          Latent diffusion, while introducing some new ideas.
                        </h3>
                      </div>
                    </a>
                  </div>
                </div>

                <div class="cursor-pointer border rounded-lg">
                  <div
                    class="rounded-xl bg-cover bg-center h-96 lg:h-96 w-full"
                    :style="
                      'background-image: url(' +
                      'https://www.segmind.com/_next/image?url=%2Frealisticvision1.jpeg&w=3840&q=75' +
                      ')'
                    "
                  >
                    <a class="flex items-end justify-center h-full w-ful">
                      <div
                        class="p-3 rounded-md bg-opacity-80 bg-gray-700 text-gray-100 w-full"
                      >
                        <h3 class="text-lg font-medium font-sans">
                          Cyber Realistic ( coming soon )
                        </h3>

                        <h3 class="text-sm mt-2">
                          The most versatile photorealistic model that blends
                          various models to achieve the amazing realistic
                          images.
                        </h3>
                      </div>
                    </a>
                  </div>
                </div>

                <div class="cursor-pointer border rounded-lg">
                  <div
                    class="rounded-xl bg-cover bg-center h-96 lg:h-96 w-full"
                    :style="
                      'background-image: url(' +
                      'https://www.segmind.com/rpg1.jpeg' +
                      ')'
                    "
                  >
                    <a class="flex items-end justify-center h-full w-ful">
                      <div
                        class="p-3 rounded-md bg-opacity-80 bg-gray-700 text-gray-100 w-full"
                      >
                        <h3 class="text-lg font-medium font-sans">
                          Paragon ( coming soon )
                        </h3>

                        <h3 class="text-sm mt-2">
                          This model corresponds to the Stable Diffusion Paragon
                          checkpoint for detailed images at the cost of a super
                          detailed
                        </h3>
                      </div>
                    </a>
                  </div>
                </div>

                <div class="cursor-pointer border rounded-lg">
                  <div
                    class="rounded-xl border bg-cover bg-center h-96 lg:h-96 w-full"
                    :style="
                      'background-image: url(' +
                      'https://www.segmind.com/tiny_sd.jpeg' +
                      ')'
                    "
                  >
                    <a class="flex items-end justify-center h-full w-ful">
                      <div
                        class="p-3 bg-opacity-80 bg-gray-700 text-gray-100 w-full"
                      >
                        <h3 class="text-lg font-medium font-sans">
                          Realistic Vision ( coming soon )
                        </h3>

                        <h3 class="text-sm mt-2">
                          This model corresponds to the Stable Diffusion
                          Realistic Vision checkpoint for detailed images at the
                          cost of a super
                        </h3>
                      </div>
                    </a>
                  </div>
                </div>

                <div class="cursor-pointer border rounded-lg">
                  <div
                    class="rounded-xl bg-cover bg-center h-96 lg:h-96 w-full"
                    :style="
                      'background-image: url(' +
                      'https://www.segmind.com/scifi.jpeg' +
                      ')'
                    "
                  >
                    <a class="flex items-end justify-center h-full w-ful">
                      <div
                        class="p-3 rounded-md bg-opacity-80 bg-gray-700 text-gray-100 w-full"
                      >
                        <h3 class="text-lg font-medium font-sans">
                          Reliberate ( coming soon )
                        </h3>

                        <h3 class="text-sm mt-2">
                          This model corresponds to the Stable Diffusion
                          Reliberate checkpoint for detailed
                        </h3>
                      </div>
                    </a>
                  </div>
                </div>

                <div class="cursor-pointer border rounded-lg">
                  <div
                    class="rounded-xl bg-cover bg-center h-96 lg:h-96 w-full"
                    :style="
                      'background-image: url(' +
                      'https://www.segmind.com/sdxl2.jpeg' +
                      ')'
                    "
                  >
                    <a class="flex items-end justify-center h-full w-ful">
                      <div
                        class="p-3 rounded-md bg-opacity-80 bg-gray-700 text-gray-100 w-full"
                      >
                        <h3 class="text-lg font-medium font-sans">
                          Revanimated ( coming soon )
                        </h3>

                        <h3 class="text-sm mt-2">
                          This model corresponds to the Stable Diffusion
                          Revanimated checkpoint for detailed images at the cost
                          of a super
                        </h3>
                      </div>
                    </a>
                  </div>
                </div>

                <div class="cursor-pointer border rounded-lg">
                  <div
                    class="rounded-xl bg-cover bg-center h-96 lg:h-96 w-full"
                    :style="
                      'background-image: url(' +
                      'https://www.segmind.com/sdxl.jpeg' +
                      ')'
                    "
                  >
                    <a class="flex items-end justify-center h-full w-ful">
                      <div
                        class="p-3 rounded-md bg-opacity-80 bg-gray-700 text-gray-100 w-full"
                      >
                        <h3 class="text-lg font-medium font-sans">
                          Colorful ( coming soon )
                        </h3>

                        <h3 class="text-sm mt-2">
                          This model corresponds to the Stable Diffusion
                          Colorful checkpoint for detailed images at the cost of
                          a super detailed prompt
                        </h3>
                      </div>
                    </a>
                  </div>
                </div>

                <div class="cursor-pointer border rounded-lg">
                  <div
                    class="rounded-xl bg-cover bg-center h-96 lg:h-96 w-full"
                    :style="
                      'background-image: url(' +
                      'https://www.segmind.com/samaritan_3d.jpeg' +
                      ')'
                    "
                  >
                    <a class="flex items-end justify-center h-full w-ful">
                      <div
                        class="p-3 rounded-md bg-opacity-80 bg-gray-700 text-gray-100 w-full"
                      >
                        <h3 class="text-lg font-medium font-sans">
                          Cartoon ( coming soon )
                        </h3>

                        <h3 class="text-sm mt-2">
                          This model corresponds to the Stable Diffusion Disney
                          checkpoint for detailed images at the cost of a super
                          detailed
                        </h3>
                      </div>
                    </a>
                  </div>
                </div>

                <div class="cursor-pointer border rounded-lg">
                  <div
                    class="rounded-xl bg-cover bg-center h-96 lg:h-96 w-full"
                    :style="
                      'background-image: url(' +
                      'https://www.segmind.com/_next/image?url=%2Fedgeofrealism1.jpeg&w=3840&q=75' +
                      ')'
                    "
                  >
                    <a class="flex items-end justify-center h-full w-ful">
                      <div
                        class="p-3 rounded-md bg-opacity-80 bg-gray-700 text-gray-100 w-full"
                      >
                        <h3 class="text-lg font-medium font-sans">
                          Edge of Realism ( coming soon )
                        </h3>

                        <h3 class="text-sm mt-2">
                          This model corresponds to the Stable Diffusion Edge of
                          Realism checkpoint for detailed images at the cost of
                          a super
                        </h3>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <StickFooter />
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/Footer.vue";
import FundSummary from "@/components/FundSummary.vue";
import Social from "@/components/Social.vue";
import LiveTx from "@/components/LiveTx.vue";
import Stats from "@/components/Stats.vue";
import OurTeam from "@/components/OurTeam.vue";
import Roadmap from "@/components/Roadmap.vue";
import Stake from "@/components/Stake.vue";

import Featured_Prompts from "./home/Featured_Prompts.vue";
import Newest_Prompts from "./home/Newest_Prompts.vue";
import Trending_Prompts from "./home/Trending_Prompts.vue";
import Free_Prompts from "./home/Free_Prompts.vue";
import Sexy_Prompt from "./home/Sexy_Prompt.vue";

import Faq from "./faq.vue";

import { mapGetters, mapActions } from "vuex";

import NFTs from "@/api/NFTs";

import { POOL_ADDRESS, VERSION } from "../../config";

import { defineComponent } from "vue";
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";

// import { products, main_product, products_2 } from "../../products";

export default {
  data() {
    return {
      version: VERSION,
      products: [],
      products_2: [],

      ref: this.$route.params.ref,
      searchPrompt: null,
      brand: [
        { name: "DALL-E", value: false },
        { name: "Midjourney", value: false },
        { name: "PromptBase", value: false },
        { name: "BlueWillow", value: false },
        { name: "Stable Diffusion", value: false },
        { name: "ChatGPT", value: false },
      ],
    };
  },
  components: {
    Social,
    LiveTx,
    Stats,
    OurTeam,
    Roadmap,
    Stake,
    ConnectWallet,
    StickFooter,
    FundSummary,
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Featured_Prompts,
    Trending_Prompts,
    Newest_Prompts,
    Faq,
    Free_Prompts,
    Sexy_Prompt,
  },
  methods: {
    fixed(a) {
      return (a.toFixed(0) / 1).toLocaleString();
    },
    digit(a, digi) {
      if (a) {
        return a.toLocaleString(undefined, {
          minimumFractionDigits: digi,
        });
      }
    },
    getNFTs() {
      NFTs.list()
        .then((res) => {
          this.products_2 = res.data.nfts.filter((p) => {
            return p.brand == "auto rebalance";
          });

          this.products = res.data.nfts.filter((p) => {
            return p.brand == "cryptonia" || p.brand == "auto rebalance";
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    submitSearch(data) {
      if (this.searchPrompt) {
        this.$router.push("/search/" + this.searchPrompt);
      } else {
        this.$router.push("/search");
      }
    },
  },
  created() {
    this.getNFTs();
    if (this.$route.params.ref) {
      localStorage.setItem("Ref", JSON.stringify(this.$route.params.ref));
    } else {
      localStorage.setItem("Ref", JSON.stringify(POOL_ADDRESS));
    }
  },
};
</script>

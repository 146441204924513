<template>
  <div class="px-4 mx-auto sm:max-w-3xl md:max-w-full lg:max-w-screen-3xl">
    <div class="md:px-24">
      <div
        class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12"
      >
        <h2
          class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight sm:text-4xl md:mx-auto"
        >
          <span class="relative inline-block">
            <svg
              viewBox="0 0 52 24"
              fill="currentColor"
              class="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                  id="247432cb-6e6c-4bec-9766-564ed7c230dc"
                  x="0"
                  y="0"
                  width=".135"
                  height=".30"
                >
                  <circle cx="1" cy="1" r=".7"></circle>
                </pattern>
              </defs>
              <rect
                fill="url(#247432cb-6e6c-4bec-9766-564ed7c230dc)"
                width="52"
                height="24"
              ></rect>
            </svg>
          </span>
          Farms
        </h2>
      </div>

      <div class="grid grid-cols-1 gap-3 lg:grid-cols-3">
        <div v-for="invest in investIist_farm" :key="invest._id">
          <div>
            <div
              class="w-full inline-block mt-1 p-px bg-gradient-to-r from-blue-700 to-pink-500 rounded"
            >
              <div
                class="inline-block w-full text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-yellow-50 bg-gray-900"
              >
                <div
                  class="grid grid-cols-3 gap-2 lg:grid-cols-3 text-gray-400"
                >
                  <div class="col-span-3">
                    <div
                      class="inline-block text-xl px-2 py-1 sm:px-4 sm:py-2 border-none rounded cursor-pointer"
                    >
                      <img
                        :src="'/tokens/' + invest.pair_1 + '.png'"
                        class="inline-block mr-1 h-10"
                      />
                      <img
                        :src="'/tokens/' + invest.pair_2 + '.png'"
                        class="inline-block mr-1 h-10"
                      />

                      {{ invest.pair_1 }} / {{ invest.pair_2 }}
                    </div>
                  </div>

                  <!-- <div class="col-span-1">
                    <div class="flex flex-col items-end mt-3">APR</div>
                  </div> -->

                  <div class="col-span-2">
                    <div class="">
                      Earn :
                      <img
                        src="/tokens/HBX.png"
                        class="inline-block mr-1 h-4"
                      />
                      HBX / LP
                    </div>
                  </div>

                  <div class="col-span-1">
                    <div class="flex flex-col items-end text-xl text-gray-200">
                      <a>
                        {{ invest.pay_to_day }}
                        <a style="font-size: 12px">HBX / day</a>
                      </a>
                    </div>
                  </div>
                </div>

                <br />
                <hr class="text-gray-800" />
                <br />

                <div class="text-gray-400">
                  <div class="grid grid-cols-3 gap-2 lg:grid-cols-3">
                    <div class="col-span-2">
                      <div class="">
                        <img
                          src="/tokens/HBX.png"
                          class="inline-block mr-1 h-4"
                        />
                        Total LP Staking in wallet
                      </div>
                    </div>

                    <div class="col-span-1">
                      <div
                        class="flex flex-col items-end text-base text-gray-200"
                      >
                        {{ invest.staking }}
                      </div>
                    </div>
                  </div>

                  <div class="grid grid-cols-3 gap-2 lg:grid-cols-3 mt-2">
                    <div class="col-span-2">
                      <div class="">
                        <img
                          src="/tokens/HBX.png"
                          class="inline-block mr-1 h-4"
                        />
                        Claim
                      </div>
                    </div>

                    <div class="col-span-1">
                      <div
                        class="flex flex-col items-end text-base text-gray-200"
                      >
                        {{ invest.claim }}
                      </div>
                    </div>
                  </div>

                  <div class="grid grid-cols-3 gap-2 lg:grid-cols-3 mt-2">
                    <div class="col-span-2">
                      <div class="">Fee unstake</div>
                    </div>

                    <div class="col-span-1">
                      <div
                        class="flex flex-col items-end text-base text-gray-200"
                      >
                        {{ invest.withdraw_fee }} %
                      </div>
                    </div>
                  </div>

                  <div class="mt-5" v-if="invest.appove == false">
                    <router-link
                      v-bind:to="'/stakeLP/' + invest.currencyIN"
                      tag="div"
                    >
                      <button
                        class="w-full h-10 flex-none sm:text-lg md:text-base px-6 py-2 border rounded text-yellow-50 border-none bg-gradient-to-r from-blue-700 to-pink-600"
                      >
                        Approve
                      </button>
                    </router-link>
                  </div>

                  <div v-if="invest.appove == true">
                    <div v-if="invest.stake == true" class="mt-10">
                      <a
                        v-on:click="invest.stake = false"
                        class="flex items-center space-x-2 text-center cursor-pointer"
                        ><i class="fad fa-arrow-left mr-1"></i
                        ><span>Go Back</span></a
                      >
                      <div class="grid grid-cols-3 gap-2 lg:grid-cols-3 mt-4">
                        <div class="col-span-2">
                          <div class="">Total Cake-LP in balance</div>
                        </div>

                        <div class="col-span-1">
                          <div
                            class="flex flex-col items-end text-base text-gray-200"
                          >
                            {{ invest.tokenBalance }}
                          </div>
                        </div>
                      </div>

                      <input
                        class="shadow appearance-none border border-blue-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                        type="Number"
                        v-model="invest_amount"
                        placeholder="0.0000"
                      />

                      <button
                        class="w-full h-10 flex-none sm:text-lg md:text-base px-6 py-2 border rounded text-yellow-50 border-none bg-gradient-to-r from-green-600 to-blue-600"
                        @click="Confirm_Stake_LP(invest)"
                      >
                        Confirm Stake LP tokens
                      </button>
                    </div>

                    <div
                      v-if="invest.stake == false && invest.unstake == false"
                    >
                      <div class="mt-5">
                        <button
                          class="w-full h-10 flex-none sm:text-lg md:text-base px-6 py-2 border rounded text-yellow-50 border-none bg-gradient-to-r from-blue-700 to-green-500"
                          @click="Stake_LP(invest)"
                        >
                          Stake LP
                        </button>
                      </div>

                      <div class="mt-5">
                        <button
                          class="w-full h-10 flex-none sm:text-lg md:text-base px-6 py-2 border rounded text-yellow-50 border-none bg-gradient-to-r from-blue-700 to-green-500"
                          @click="Unstake_LP(invest)"
                        >
                          Unstake LP
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="mt-5">
                    <div class="grid grid-cols-3 gap-2 lg:grid-cols-3">
                      <div class="col-span-2">
                        <div class="">Total LP Staking</div>
                      </div>

                      <div class="col-span-1">
                        <div
                          class="flex flex-col items-end text-base text-gray-200"
                        >
                          {{ invest.liquidity_pool }}
                        </div>
                      </div>
                    </div>

                    <div class="grid grid-cols-3 gap-2 lg:grid-cols-3 mt-2">
                      <div class="col-span-2">
                        <div class="">Total HBX Earned</div>
                      </div>

                      <div class="col-span-1">
                        <div
                          class="flex flex-col items-end text-base text-gray-200"
                        >
                          {{ invest.total_earn }}
                        </div>
                      </div>
                    </div>

                    <div class="mt-3">
                      <a
                        href="https://pancakeswap.finance/swap?outputCurrency=0x7b674129d7ce4b17d7d69c4c68a00aa429230e88"
                        target="_blink"
                      >
                        <i
                          class="fas fa-external-link-alt"
                          style="color: green"
                        ></i>

                        Buy and add Liquidity
                        <img
                          src="/tokens/HBX.png"
                          class="inline-block mr-1 h-4"
                        />
                      </a>
                    </div>

                    <div class="mt-3">
                      <a
                        target="_blink"
                        href="https://bscscan.com/token/0x7b674129d7ce4b17d7d69c4c68a00aa429230e88"
                      >
                        <i
                          class="fas fa-external-link-alt"
                          style="color: green"
                        ></i>
                        View contract
                      </a>
                    </div>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 mt-20"
      >
        <h2
          class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight sm:text-4xl md:mx-auto"
        >
          <span class="relative inline-block">
            <svg
              viewBox="0 0 52 24"
              fill="currentColor"
              class="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                  id="247432cb-6e6c-4bec-9766-564ed7c230dc"
                  x="0"
                  y="0"
                  width=".135"
                  height=".30"
                >
                  <circle cx="1" cy="1" r=".7"></circle>
                </pattern>
              </defs>
              <rect
                fill="url(#247432cb-6e6c-4bec-9766-564ed7c230dc)"
                width="52"
                height="24"
              ></rect>
            </svg>
          </span>
          Pools
        </h2>
      </div>

      <div class="grid grid-cols-1 gap-3 lg:grid-cols-3">
        <div v-for="invest in investIist_pool" :key="invest._id">
          <div>
            <div
              class="w-full inline-block mt-1 p-px bg-gradient-to-r from-blue-700 to-pink-500 rounded"
            >
              <div
                class="inline-block w-full text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-yellow-50 bg-gray-900"
              >
                <div
                  class="grid grid-cols-3 gap-2 lg:grid-cols-3 text-gray-400"
                >
                  <div class="col-span-3">
                    <div
                      class="inline-block text-xl px-2 py-1 sm:px-4 sm:py-2 border-none rounded cursor-pointer"
                    >
                      <img
                        :src="'/tokens/' + invest.pair_1 + '.png'"
                        class="inline-block mr-1 h-10"
                      />

                      Stake {{ invest.pair_1 }}
                    </div>
                  </div>

                  <!-- <div class="col-span-1">
                    <div class="flex flex-col items-end mt-3">APR</div>
                  </div> -->

                  <div class="col-span-2">
                    <div class="">
                      Earn :
                      <img
                        src="/tokens/HBX.png"
                        class="inline-block mr-1 h-4"
                      />
                      HBX
                    </div>
                  </div>

                  <div class="col-span-1">
                    <div class="flex flex-col items-end text-xl text-gray-200">
                      <a>
                        {{ invest.pay_to_day }}
                        <a style="font-size: 12px">HBX / day</a>
                      </a>
                    </div>
                  </div>
                </div>

                <br />
                <hr class="text-gray-800" />
                <br />

                <div class="text-gray-400">
                  <div class="grid grid-cols-3 gap-2 lg:grid-cols-3">
                    <div class="col-span-2">
                      <div class="">
                        <img
                          src="/tokens/HBX.png"
                          class="inline-block mr-1 h-4"
                        />
                        Total Staking {{ invest.pair_1 }} in wallet
                      </div>
                    </div>

                    <div class="col-span-1">
                      <div
                        class="flex flex-col items-end text-base text-gray-200"
                      >
                        {{ invest.staking }}
                      </div>
                    </div>
                  </div>

                  <div class="grid grid-cols-3 gap-2 lg:grid-cols-3 mt-2">
                    <div class="col-span-2">
                      <div class="">
                        <img
                          src="/tokens/HBX.png"
                          class="inline-block mr-1 h-4"
                        />
                        Claim
                      </div>
                    </div>

                    <div class="col-span-1">
                      <div
                        class="flex flex-col items-end text-base text-gray-200"
                      >
                        {{ invest.claim }}
                      </div>
                    </div>
                  </div>

                  <div class="mt-5">
                    <router-link
                      v-bind:to="'/stakeLP/' + invest.currencyIN"
                      tag="div"
                    >
                      <button
                        class="w-full h-10 flex-none sm:text-lg md:text-base px-6 py-2 border rounded text-yellow-50 border-none bg-gradient-to-r from-blue-700 to-pink-600"
                      >
                        Approve
                      </button>
                    </router-link>

                    <!-- <button
                      class="w-full h-10 flex-none sm:text-lg md:text-base px-6 py-2 border rounded text-yellow-50 border-none bg-gradient-to-r from-blue-700 to-red-500"
                    >
                      Approve
                    </button> -->
                  </div>

                  <div class="mt-5">
                    <div class="grid grid-cols-3 gap-2 lg:grid-cols-3">
                      <div class="col-span-2">
                        <div class="">Total {{ invest.pair_1 }} Staking</div>
                      </div>

                      <div class="col-span-1">
                        <div
                          class="flex flex-col items-end text-base text-gray-200"
                        >
                          {{ invest.liquidity_pool }}
                        </div>
                      </div>
                    </div>

                    <div class="grid grid-cols-3 gap-2 lg:grid-cols-3 mt-2">
                      <div class="col-span-2">
                        <div class="">Total HBX Earned</div>
                      </div>

                      <div class="col-span-1">
                        <div
                          class="flex flex-col items-end text-base text-gray-200"
                        >
                          {{ parseFloat(invest.total_earn).toFixed(2) }}
                        </div>
                      </div>
                    </div>

                    <div class="mt-3">
                      <a
                        href="https://pancakeswap.finance/swap?outputCurrency=0x7b674129d7ce4b17d7d69c4c68a00aa429230e88"
                        target="_blink"
                      >
                        <i
                          class="fas fa-external-link-alt"
                          style="color: green"
                        ></i>

                        Buy and add Liquidity
                        <img
                          src="/tokens/HBX.png"
                          class="inline-block mr-1 h-4"
                        />
                      </a>
                    </div>

                    <div class="mt-3">
                      <a
                        target="_blink"
                        href="https://bscscan.com/token/0x7b674129d7ce4b17d7d69c4c68a00aa429230e88"
                      >
                        <i
                          class="fas fa-external-link-alt"
                          style="color: green"
                        ></i>
                        View contract
                      </a>
                    </div>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import nativeToast from "native-toast";

import investbox from "@/api/investbox";

import Swal from "sweetalert2";

import Web3 from "web3";

import { POOL_ADDRESS } from "../config";

export default {
  components: {},
  computed: mapGetters(["getToken", "getUser"]),

  data() {
    return {
      investIist_farm: [],
      investIist_pool: [],
      invest_amount: "",
      contractList: [],
    };
  },
  methods: {
    async Invest_list() {
      investbox.list().then((res) => {
        var farm = [];

        var pool = [];

        res.data.map((r) => {
          r.appove = false;
          r.stake = false;
          r.unstake = false;

          if (this.contractList.length > 0) {
            r.claim = "-";

            r.staking = "-";

            this.contractList.map((c) => {
              if (c.currencyIN == r.currencyIN) {
                const diffTime = Math.abs(new Date(c.date) - new Date());

                var dififfMinute = Math.ceil(diffTime / (1000 * 60));

                var pay = parseFloat(r.pay_to_day) / (24 * 60);

                c.claim = parseFloat(
                  parseFloat(dififfMinute) *
                    parseFloat(pay) *
                    parseFloat(c.amount) +
                    parseFloat(c.claim)
                ).toFixed(2);

                r.staking = c.amount;
                r.claim = parseFloat(
                  parseFloat(c.claim) + parseFloat(c.total_claim)
                ).toFixed(2);
              } else {
              }

              // if (c.currencyIN == r.currencyIN) {
              //   const diffTime = Math.abs(new Date(c.date) - new Date());

              //   var dififfMinute = Math.ceil(diffTime / (1000 * 60));

              //   var pay = parseFloat(r.pay_to_day) / (24 * 60);

              //   c.claim = parseFloat(
              //     parseFloat(dififfMinute) *
              //       parseFloat(pay) *
              //       parseFloat(c.amount) +
              //       parseFloat(c.claim)
              //   ).toFixed(2);

              //   r.staking = c.amount;
              //   r.claim = parseFloat(c.claim) + parseFloat(c.total_claim);
              // } else {
              //   r.staking = "-";
              //   r.claim = "-";
              // }
            });
          }

          if (r.type_earn == "farm") {
            farm.push(r);
          } else if (r.type_earn == "pool") {
            pool.push(r);
          }
        });

        this.investIist_farm = farm;
        this.investIist_pool = pool;
      });
    },

    async appove_LP(invest) {
      if (this.getToken) {
        if (invest.type_earn == "farm") {
          const web3 = new Web3(ethereum);

          if (invest.appove == false) {
            invest.appove = true;
          }
        }
      } else {
        nativeToast({
          message: "Please Connect Metamask.",
          position: "top",
          timeout: 3000,
          type: "error",
        });
      }
    },
    async Stake_LP(invest) {
      if (invest.type_earn == "farm") {
        const web3 = new Web3(ethereum);

        if (invest.stake == false) {
          invest.stake = true;

          const tokenInst = new web3.eth.Contract(tokenABI, invest.currencyIN);
          const token_raw = await tokenInst.methods
            .balanceOf(this.getUser.address)
            .call();

          if (token_raw.length)
            if (token_raw) {
              invest.tokenBalance = (token_raw / Math.pow(10, 18)).toFixed(4);

              // return invest;
            }
        }
      }
    },

    Unstake_LP(invest) {
      invest.stake = false;
      invest.unstake = true;
    },

    async list_contracts() {
      await investbox
        .listByAddress({
          address: this.getUser.address,
        })
        .then((res) => {
          this.contractList = res.data;
          this.Invest_list();
        })
        .catch((error) => {});
    },
  },
  created() {
    if (this.getUser) {
      this.list_contracts();
    } else {
      this.Invest_list();
    }
  },
  updated() {},
};
</script>
<style scoped></style>

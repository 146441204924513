<template>
  <div>
    <div class="z-0">
      <div
        class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10 pt-10 p-2"
      >
        <div class="flex">
          <div class="">
            <span class="fa fa-quote-left fa-2x text-white"></span>
          </div>
          <a
            class="ml-2 font-medium text-xl xl:text-2xl text-gray-100 transition duration-300 ease-in-out transform hover:-translate-y-1 cursor-pointer"
            href="/search/News"
          >
            Newest Prompts
          </a>
        </div>

        <hr class="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />

        <div class="grid grid-cols-2 gap-2 lg:grid-cols-4 md:grid-cols-3">
          <div
            class="transition duration-300 ease-in-out transform hover:-translate-y-1 cursor-pointer rounded"
            style="margin-top: 5px"
            v-for="s in prompts"
            :key="s"
          >
            <div
              class="p-0.5 bg-opacity-25 bg-gradient-to-r from-purple-400 to-pink-400 rounded-lg"
            >
              <div class="text-sm border-none rounded">
                <a class="" :href="linkDetail(s)">
                  <div
                    class="rounded-t-lg bg-cover bg-center h-44 md:h-48 lg:h-80 w-full inset-0 object-cover ease-in-out opacity-100 opacity-100 hover:opacity-0 border-b"
                    :style="'background-image: url(' + s.img_1 + ')'"
                  >
                    <div class="h-full w-ful">
                      <div class="flex p-2">
                        <div
                          class="flex border rounded bg-blue-600 bg-opacity-75"
                        >
                          <div class="text-xs p-1 text-gray-100">
                            <i class="fa fa-check-square text-gray-200"></i>
                            {{ s.prompt_type }}
                          </div>
                        </div>
                      </div>

                      <a
                        class="flex items-end justify-center w-full h-36 md:h-40 lg:h-72"
                        :href="'/account/' + s.address"
                      >
                        <div
                          class="flex border-2 border-blue-300 p-2 rounded-md bg-gray-700 bg-opacity-90"
                        >
                          <img src="tokens/BNB.png" class="w-5" />
                          <b
                            class="ml-1 text-gray-100"
                            v-if="s.address != 'free'"
                          >
                            {{ s.address.slice(0, 5) }} ...
                            {{
                              s.address.slice(
                                s.address.length - 6,
                                s.address.length - 1
                              )
                            }}</b
                          >
                          <b class="ml-1 text-gray-100" v-else>
                            {{ s.address }} prompt
                          </b>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div
                    class="rounded-t-lg bg-cover bg-center h-44 md:h-48 lg:h-80 w-full absolute inset-0 object-cover transition duration-500 ease-in-out opacity-0 hover:opacity-100 mt-0.5 border-b"
                    :style="'background-image: url(' + s.img_2 + ')'"
                  >
                    <div class="h-full w-ful">
                      <div class="flex p-2">
                        <div
                          class="flex border rounded bg-blue-600 bg-opacity-75"
                        >
                          <div class="text-xs p-1 text-gray-100">
                            <i class="fa fa-check-square text-gray-200"></i>
                            {{ s.prompt_type }}
                          </div>
                        </div>
                      </div>

                      <div class="flex items-end justify-center w-full h-72">
                        <a
                          :href="'/account/' + s.address"
                          class="flex border-2 border-blue-300 p-2 rounded-md bg-gray-700 bg-opacity-90"
                        >
                          <img src="tokens/BNB.png" class="w-5" />
                          <b
                            class="ml-1 text-gray-100"
                            v-if="s.address != 'free'"
                          >
                            {{ s.address.slice(0, 5) }} ...
                            {{
                              s.address.slice(
                                s.address.length - 6,
                                s.address.length - 1
                              )
                            }}</b
                          >
                          <b class="ml-1 text-gray-100" v-else>
                            {{ s.address }} prompt
                          </b>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="bg-gray-900 rounded-b-lg pt-2">
                    <div class="flex-none">
                      <h4 class="text-base p-2 text-gray-200">
                        ⛵ {{ s.title.slice(0, 34) }} ...
                      </h4>
                    </div>

                    <div class="grid grid-cols-2 mt-1 pb-2">
                      <div class="text-center">
                        <h3 class="text-gray-400 text-sm">Price</h3>
                        <h3 class="text-lg font-normal font-sans text-gray-100">
                          $ {{ s.price }}
                        </h3>
                      </div>
                      <div
                        class="flex justify-center items-center text-gray-100"
                      >
                        <div class="text-center">
                          <h3 class="text-sm font-bold">
                            <i class="fas fa-eye"></i>

                            {{ s.view }}

                            <i class="fas fa-heart ml-2"></i>

                            {{ s.favorite }}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col items-center mt-8">
          <a
            href="/search/News"
            class="text-xl font-bold xl:text-xl text-transparent text-gray-200 transition duration-300 ease-in-out transform hover:-translate-y-1 cursor-pointer text-shadow-white"
          >
            More Prompts <i class="fas fa-arrow-circle-right"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/StickFooter.vue";
import FundSummary from "@/components/FundSummary.vue";
import Social from "@/components/Social.vue";
import LiveTx from "@/components/LiveTx.vue";
import Stats from "@/components/Stats.vue";
import OurTeam from "@/components/OurTeam.vue";
import Roadmap from "@/components/Roadmap.vue";
import Stake from "@/components/Stake.vue";

import { mapGetters, mapActions } from "vuex";

import Prompt from "@/api/prompt";

import { POOL_ADDRESS, VERSION } from "../../../config";

import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";

export default {
  data() {
    return {
      version: VERSION,
      products: [],
      products_2: [],
      prompts: [],

      ref: this.$route.params.ref,
    };
  },
  components: {
    Social,
    LiveTx,
    Stats,
    OurTeam,
    Roadmap,
    Stake,
    ConnectWallet,
    StickFooter,
    FundSummary,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  methods: {
    fixed(a) {
      return (a.toFixed(0) / 1).toLocaleString();
    },
    digit(a, digi) {
      if (a) {
        return a.toLocaleString(undefined, {
          minimumFractionDigits: digi,
        });
      }
    },
    linkDetail(s) {
      if (s.price == "free") {
        return "/promptFree/" + s._id;
      } else {
        return "/prompt/" + s._id;
      }
    },

    async getNewPrompts() {
      await Prompt.newPrompts()
        .then((res) => {
          this.prompts = res.data.prompts;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.getNewPrompts();
    if (this.$route.params.ref) {
      localStorage.setItem("Ref", JSON.stringify(this.$route.params.ref));
    } else {
      localStorage.setItem("Ref", JSON.stringify(POOL_ADDRESS));
    }
  },
};
</script>

<template>
  <div class="mx-auto sm:max-w-xl lg:max-w-screen-2xl md:px-24">
    <div class="grid grid-cols-2 gap-4 lg:grid-cols-4">
      <div
        class="inline-block mt-1 p-px bg-gradient-to-r from-blue-700 to-red-500 rounded text-center"
      >
        <div
          class="inline-block w-full text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-yellow-50 bg-gray-900"
        >
          <h3
            class="text-sm font-medium tracking-widest uppercase lg:text-base transition duration-300 ease-in-out"
            style="font-size: 14px"
          >
            Market Cap
          </h3>

          <br />

          <div class="sm:text-3xl text-xl font-extrabold font-sans">
            <a
              class="bg-clip-text text-transparent bg-gradient-to-r from-yellow-300 to-red-500"
              href="https://coinmarketcap.com/currencies/hashsbx/"
              target="_blink"
            >
              $425,845
            </a>
          </div>

          <h3
            class="font-sans font-bold tracking-widest uppercase ease-in-out text-transparent bg-clip-text bg-gradient-to-r from-green-600 to-blur-500"
          ></h3>

          <br />

          <h3
            class="text-sm font-medium tracking-widest uppercase lg:text-base transition duration-300 ease-in-out"
            style="font-size: 14px"
          >
            USD
          </h3>
        </div>
      </div>

      <div
        class="inline-block mt-1 p-px bg-gradient-to-r from-blue-700 to-red-500 rounded text-center"
      >
        <div
          class="inline-block w-full text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-yellow-50 bg-gray-900"
        >
          <h3
            class="text-sm font-medium tracking-widest uppercase lg:text-base transition duration-300 ease-in-out"
            style="font-size: 14px"
          >
            Total Supply
          </h3>

          <br />

          <div class="sm:text-3xl text-xl font-extrabold font-sans">
            <a
              class="bg-clip-text text-transparent bg-gradient-to-r from-yellow-300 to-red-500"
              href="https://coinmarketcap.com/currencies/hashsbx/"
              target="_blink"
            >
              1,000,000,000
            </a>
          </div>

          <br />

          <h3
            class="text-sm font-medium tracking-widest uppercase lg:text-base transition duration-300 ease-in-out"
            style="font-size: 14px"
          >
            HBX
          </h3>
        </div>
      </div>

      <div
        class="inline-block mt-1 p-px bg-gradient-to-r from-blue-700 to-red-500 rounded text-center"
      >
        <div
          class="inline-block w-full text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-yellow-50 bg-gray-900"
        >
          <h3
            class="text-sm font-medium tracking-widest uppercase lg:text-base transition duration-300 ease-in-out"
            style="font-size: 14px"
          >
            Circulating Supply
          </h3>

          <br />

          <div class="sm:text-3xl text-xl font-extrabold font-sans">
            <a
              class="bg-clip-text text-transparent bg-gradient-to-r from-yellow-300 to-red-500"
              href="https://coinmarketcap.com/currencies/hashsbx/"
              target="_blink"
            >
              629,280,963
            </a>
          </div>

          <h3
            class="font-sans font-bold tracking-widest uppercase ease-in-out text-transparent bg-clip-text bg-gradient-to-r from-green-600 to-blur-500"
          ></h3>

          <br />

          <h3
            class="text-sm font-medium tracking-widest uppercase lg:text-base transition duration-300 ease-in-out"
            style="font-size: 14px"
          >
            HBX
          </h3>
        </div>
      </div>

      <div
        class="inline-block mt-1 p-px bg-gradient-to-r from-blue-700 to-red-500 rounded text-center"
      >
        <div
          class="inline-block w-full text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-yellow-50 bg-gray-900"
        >
          <h3
            class="text-sm font-medium tracking-widest uppercase lg:text-base transition duration-300 ease-in-out"
            style="font-size: 14px"
          >
            Holders
          </h3>

          <br />

          <div class="sm:text-3xl text-xl font-extrabold font-sans">
            <a
              class="bg-clip-text text-transparent bg-gradient-to-r from-yellow-300 to-red-500"
              href="https://coinmarketcap.com/currencies/hashsbx/"
              target="_blink"
            >
              438
            </a>
          </div>

          <br />

          <h3
            class="text-sm font-medium tracking-widest uppercase lg:text-base transition duration-300 ease-in-out"
            style="font-size: 14px"
          >
            addresses
          </h3>
        </div>
      </div>

      <!-- 
      <div
        class="mt-2 text-center cursor-pointer text-gray-100 hover:text-purple-300 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110 bg-green-400 bg-opacity-25 p-5"
        style="border: 2px solid green; border-radius: 20px"
      >
        <h3
          class="text-sm font-medium tracking-widest uppercase transition duration-300 ease-in-out"
          style="font-size: 14px"
        >
         
        </h3>

        <br />

        <h3
          class="font-medium tracking-widest uppercase sm:text-lg md:text-2xl transition duration-300 ease-in-out text-yellow-400"
        >
          $ 1,263,546
        </h3>
      </div>
      <div
        class="mt-2 text-center cursor-pointer text-gray-100 hover:text-purple-300 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110 bg-green-400 bg-opacity-25 p-5"
        style="border: 2px solid green; border-radius: 20px"
      >
        <h3
          class="text-sm font-medium tracking-widest uppercase lg:text-base transition duration-300 ease-in-out"
          style="font-size: 14px"
        >
          
        </h3>

        <br />

        <h3
          class="font-medium tracking-widest uppercase sm:text-lg md:text-2xl transition duration-300 ease-in-out text-yellow-400"
        >
          $ 1,263,546
        </h3>
      </div>
      <div
        class="mt-2 text-center cursor-pointer text-gray-100 hover:text-purple-300 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110 bg-green-400 bg-opacity-25 p-5"
        style="border: 2px solid green; border-radius: 20px"
      >
        <h3
          class="text-sm font-medium tracking-widest uppercase lg:text-base transition duration-300 ease-in-out"
          style="font-size: 14px"
        >
          Total Supply
        </h3>

        <br />

        <h3
          class="font-medium tracking-widest uppercase sm:text-lg md:text-2xl transition duration-300 ease-in-out text-yellow-400"
        >
          $ 1,263,546
        </h3>
      </div> -->
    </div>
  </div>
</template>

<template>
  <div class="w-full">
    <div class="mx-auto sm:text-center">
      <div
        class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 text-white"
      >
        <h2
          class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight sm:text-4xl md:mx-auto"
        >
          <span class="relative inline-block">
            <svg
              viewBox="0 0 52 24"
              fill="currentColor"
              class="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                  id="5dc90b42-5ed4-45a6-8e63-2d78ca9d3d95"
                  x="0"
                  y="0"
                  width=".135"
                  height=".30"
                >
                  <circle cx="1" cy="1" r=".7"></circle>
                </pattern>
              </defs>
              <rect
                fill="url(#5dc90b42-5ed4-45a6-8e63-2d78ca9d3d95)"
                width="52"
                height="24"
              ></rect>
            </svg>
          </span>
          All Bet
        </h2>
      </div>

      <div class="">
        <div class="max-w-5xl">
          <div
            v-for="t in table_rows"
            v-bind:key="t.id"
            class="flex w-full bg-gray-500 bg-opacity-10 my-1 hover:bg-gray-500 hover:bg-opacity-25 p-2 rounded-md"
          >
            <div class="flex-grow flex flex-col sm:flex-row">
              <span class="hidden md:block mr-4 text-gray-500 text-sm">{{
                t.createdAt
              }}</span>
              <div class="sm:mr-2 text-gray-500 text-sm sm:text-base">
                <a
                  class="text-baseline text-blue text-blue-400 mr-4"
                  v-bind:href="`https://bscscan.com/block/${t.block}`"
                  target="_blink"
                  ><i class="fad fa-link mr-1"></i>
                  {{ t.hash.slice(0, 3) }}...{{ t.hash.slice(-4) }}
                </a>
              </div>

              <div class="text-sm sm:text-base">
                {{ t.remark }},
                <span class="text-xs">
                  fee: {{ fixed(t.fee) }} {{ t.currency }}</span
                >
              </div>
            </div>
            <div
              class="flex-none text-right flex flex-col sm:flex-row text-sm sm:text-base"
            >
              <div>
                0.157
                <img
                  class="inline-block h-4 ml-2"
                  :src="`/tokens/${t.currency}.png`"
                />
              </div>
              <div
                v-bind:class="
                  'text-xs sm:inline-block text-center font-semibold mt-1 py-1 rounded uppercase ml-10 sm:ml-2 w-24 ' +
                  t.label
                "
              >
                {{ t.status }}
              </div>
            </div>
          </div>

          <div class="text-center">
            <div
              class="text-sm mt-2 px-6 py-2 border rounded text-yellow-50 hover:bg-gray-500 hover:bg-opacity-25 border-none cursor-pointer"
              v-on:click="getdata()"
              v-if="!nomore"
            >
              Load more...
            </div>
            <div
              class="text-sm mt-2 px-6 py-2 border rounded text-yellow-50 border-none"
              v-else
            >
              - No more -
            </div>
          </div>
        </div>
      </div>

      <router-link
        to="/lastesttransactions"
        aria-label=""
        class="inline-flex items-center font-semibold transition-colors duration-200 text-gray-300 hover:text-gray-100"
        >See Lastest Transactions <i class="fad fa-chevron-right ml-2"></i>
      </router-link>
    </div>
  </div>
</template>

<script>
import func from "@/widgets/func";
import label from "@/widgets/label";
import moment from "moment";
import nativeToast from "native-toast";

import { EXPLORER } from "../config";

import blocks from "@/api/blocks";

export default {
  data() {
    return {
      search: "",
      ready: false,
      page: 0,
      nomore: false,
      expanded: null,
      address: this.$route.params.address || null,
      nomore: false,
      table_rows: [],
      explorer: EXPLORER,
    };
  },
  methods: {
    fixed(a) {
      return func.fixed(a);
    },

    expand(id) {
      if (this.expanded == id) {
        this.expanded = null;
        return;
      }
      this.expanded = id;
    },
    getdata() {
      blocks
        .bethistory({
          page: this.page,
          address: "0xcd4649e89e05f43f0d329d3d9c2e7ddd9d645b34",
        })
        .then((res) => {
          console.log(res);
          this.ready = true;
          if (res.data.length == 0) {
            this.nomore = true;
          } else {
            res.data.forEach((e) => {
              e.createdAt = moment(e.createdAt).format("YYYY-MM-DD, HH:mm:ss");

              var a = label.type(e.status);

              e.label = a.label;

              this.table_rows.push(e);
            });
            this.page = this.page + 1;
          }
        })
        .catch((error) => {
          nativeToast({
            message: error.response.data.error,
            position: "top",
            timeout: 3000,
            type: "error",
          });
        });
    },
  },
  created() {
    this.getdata();
  },
};
</script>

<template>
  <div
    class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
  >
    <div
      class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 text-gray-100"
    >
      <div>
        <p
          class="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400"
        >
          All of funds
        </p>
      </div>
      <h2
        class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight sm:text-4xl md:mx-auto"
      >
        <span class="relative inline-block">
          <svg
            viewBox="0 0 52 24"
            fill="currentColor"
            class="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
          >
            <defs>
              <pattern
                id="247432cb-6e6c-4bec-9766-564ed7c230dc"
                x="0"
                y="0"
                width=".135"
                height=".30"
              >
                <circle cx="1" cy="1" r=".7"></circle>
              </pattern>
            </defs>
            <rect
              fill="url(#247432cb-6e6c-4bec-9766-564ed7c230dc)"
              width="52"
              height="24"
            ></rect>
          </svg>
        </span>
        Funds Summary
      </h2>
    </div>
    <div v-if="!ready" class="">
      <loader class="h-40"></loader>
      <p class="text-center">Loading...</p>
    </div>
    <div v-else>
      <div
        class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4"
        v-if="getCurrency"
      >
        <div class="" v-for="b in funds" v-bind:key="b.id">
          <router-link v-bind:to="'/ranking/' + b.currency">
            <div
              class="bg-gray-900 overflow-hidden shadow rounded-lg w-56 sm:w-60 relative"
            >
              <img
                :src="getCurrency[b.currency].logo"
                alt="btc logo"
                class="h-24 w-24 rounded-full absolute opacity-50 -top-6 -right-6 md:-right-4"
              />
              <div class="px-4 py-5 sm:p-6">
                <dl>
                  <dt
                    class="text-sm leading-5 font-medium text-gray-200 truncate"
                  >
                    {{ getCurrency[b.currency].name }}
                  </dt>
                  <dd
                    class="mt-1 text-3xl sm:text-3xl leading-9 font-semibold text-white"
                  >
                    {{ fixed(b.balance) }}
                  </dd>
                  <dd class="text-gray-400 font-semibold">
                    <span> {{ fixed(b.amount) }} {{ b.currency }} </span>
                  </dd>
                </dl>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chameleon from "@/assets/svg/chameleon.vue";
import loader from "@/assets/svg/loader.vue";
import func from "@/widgets/func";
import label from "@/widgets/label";
import info from "@/api/info";
import { mapGetters } from "vuex";

export default {
  components: {
    loader,
    chameleon,
  },
  data() {
    return {
      funds: [],
      ready: false,
    };
  },
  computed: mapGetters(["getCurrency"]),
  methods: {
    fixed(a) {
      return func.fixed(a);
    },
    getData() {
      info.fundsummary().then((res) => {
        res.data.forEach((e) => {
          var a = label.type(e._id.type);
          e.label = a.label;

          this.funds.push(e);
        });
        this.ready = true;
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>

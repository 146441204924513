<template>
  <div
    class="px-4 py-16 mx-auto sm:max-w-xl lg:max-w-screen-2xl md:px-24 lg:px-8 lg:py-20"
  >
    <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
      <div>
        <p
          class="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400"
        >
          Roadmap and Product Planing
        </p>
      </div>
      <h2
        class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight sm:text-4xl md:mx-auto"
      >
        <span class="relative inline-block">
          <svg
            viewBox="0 0 52 24"
            fill="currentColor"
            class="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
          >
            <defs>
              <pattern
                id="247432cb-6e6c-4bec-9766-564ed7c230dc"
                x="0"
                y="0"
                width=".135"
                height=".30"
              >
                <circle cx="1" cy="1" r=".7"></circle>
              </pattern>
            </defs>
            <rect
              fill="url(#247432cb-6e6c-4bec-9766-564ed7c230dc)"
              width="52"
              height="24"
            ></rect>
          </svg>
        </span>
        Roadmap
      </h2>
    </div>

    <div class="grid gap-6 row-gap-10 lg:grid-cols-2">
      <div class="lg:py-6 lg:pr-16">
        <div class="flex">
          <div class="flex flex-col items-center mr-4">
            <div>
              <div
                class="flex items-center justify-center w-10 h-10 border rounded-full"
              >
                <i class="fad fa-arrow-down"></i>
              </div>
            </div>
            <div class="w-px h-full bg-gray-300"></div>
          </div>
          <div class="pt-1 pb-8">
            <p class="mb-2 text-lg font-bold">Q2 2019</p>

            <div
              class="sm:w-90 w-full inline-block mt-1 p-px bg-gradient-to-r from-blue-700 to-pink-300 rounded"
            >
              <div
                class="inline-block w-full px-2 py-1 sm:px-4 sm:py-2 border-none rounded bg-gray-900"
              >
                <div class="text-gray-400">
                  <div class="col-span-2 flex flex-col justify-center">
                    <div class="sm:text-lg text-sm font-bold font-sans">
                      ✅
                      <span
                        class="bg-clip-text text-transparent bg-gradient-to-r from-gray-300 to-green-500"
                      >
                        Website Launching
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="sm:w-90 w-full inline-block mt-1 p-px bg-gradient-to-r from-pink-300 to-blue-700 rounded"
            >
              <div
                class="inline-block w-full px-2 py-1 sm:px-4 sm:py-2 border-none rounded bg-gray-900"
              >
                <div class="text-gray-400">
                  <div class="col-span-2 flex flex-col justify-center">
                    <div class="sm:text-lg text-sm font-bold font-sans">
                      ✅
                      <span
                        class="bg-clip-text text-transparent bg-gradient-to-r from-gray-300 to-green-500"
                      >
                        List on Website
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="sm:w-90 w-full inline-block mt-1 p-px bg-gradient-to-r from-blue-700 to-pink-300 rounded"
            >
              <div
                class="inline-block w-full px-2 py-1 sm:px-4 sm:py-2 border-none rounded bg-gray-900"
              >
                <div class="text-gray-400">
                  <div class="col-span-2 flex flex-col justify-center">
                    <div class="sm:text-lg text-sm font-bold font-sans">
                      ✅
                      <span
                        class="bg-clip-text text-transparent bg-gradient-to-r from-gray-300 to-green-500"
                      >
                        List on Pancake Swap DEX
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="sm:w-90 w-full inline-block mt-1 p-px bg-gradient-to-r from-pink-300 to-blue-700 rounded"
            >
              <div
                class="inline-block w-full px-2 py-1 sm:px-4 sm:py-2 border-none rounded bg-gray-900"
              >
                <div class="text-gray-400">
                  <div class="col-span-2 flex flex-col justify-center">
                    <div class="sm:text-lg text-sm font-bold font-sans">
                      ✅
                      <span
                        class="bg-clip-text text-transparent bg-gradient-to-r from-gray-300 to-green-500"
                      >
                        Live Coin Watching List
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="sm:w-90 w-full inline-block mt-1 p-px bg-gradient-to-r from-blue-700 to-pink-300 rounded"
            >
              <div
                class="inline-block w-full px-2 py-1 sm:px-4 sm:py-2 border-none rounded bg-gray-900"
              >
                <div class="text-gray-400">
                  <div class="col-span-2 flex flex-col justify-center">
                    <div class="sm:text-lg text-sm font-bold font-sans">
                      ✅
                      <span
                        class="bg-clip-text text-transparent bg-gradient-to-r from-gray-300 to-green-500"
                      >
                        CoinGecko Listing
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="sm:w-90 w-full inline-block mt-1 p-px bg-gradient-to-r from-pink-300 to-blue-700 rounded"
            >
              <div
                class="inline-block w-full px-2 py-1 sm:px-4 sm:py-2 border-none rounded bg-gray-900"
              >
                <div class="text-gray-400">
                  <div class="col-span-2 flex flex-col justify-center">
                    <div class="sm:text-lg text-sm font-bold font-sans">
                      ✅
                      <span
                        class="bg-clip-text text-transparent bg-gradient-to-r from-gray-300 to-green-500"
                      >
                        Coinmarketcap Listing
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex">
          <div class="flex flex-col items-center mr-4">
            <div>
              <div
                class="flex items-center justify-center w-10 h-10 border rounded-full"
              >
                <i class="fad fa-arrow-down"></i>
              </div>
            </div>
            <div class="w-px h-full bg-gray-300"></div>
          </div>
          <div class="pt-1 pb-8">
            <p class="mb-2 text-lg font-bold">Q3 2020 - 2023</p>

            <div
              class="sm:w-90 w-full inline-block mt-1 p-px bg-gradient-to-r from-blue-700 to-pink-300 rounded"
            >
              <div
                class="inline-block w-full px-2 py-1 sm:px-4 sm:py-2 border-none rounded bg-gray-900"
              >
                <div class="text-gray-400">
                  <div class="col-span-2 flex flex-col justify-center">
                    <div class="sm:text-lg text-sm font-bold font-sans">
                      ✅
                      <span
                        class="bg-clip-text text-transparent bg-gradient-to-r from-gray-300 to-green-500"
                      >
                        New Website (Phase 1)
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="sm:w-90 w-full inline-block mt-1 p-px bg-gradient-to-r from-pink-300 to-blue-700 rounded"
            >
              <div
                class="inline-block w-full px-2 py-1 sm:px-4 sm:py-2 border-none rounded bg-gray-900"
              >
                <div class="text-gray-400">
                  <div class="col-span-2 flex flex-col justify-center">
                    <div class="sm:text-lg text-sm font-bold font-sans">
                      ✅
                      <span
                        class="bg-clip-text text-transparent bg-gradient-to-r from-gray-300 to-green-500"
                      >
                        Listing on DEX
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="sm:w-90 w-full inline-block mt-1 p-px bg-gradient-to-r from-blue-700 to-pink-300 rounded"
            >
              <div
                class="inline-block w-full px-2 py-1 sm:px-4 sm:py-2 border-none rounded bg-gray-900"
              >
                <div class="text-gray-400">
                  <div class="col-span-2 flex flex-col justify-center">
                    <div class="sm:text-lg text-sm font-bold font-sans">
                      ✅
                      <span
                        class="bg-clip-text text-transparent bg-gradient-to-r from-gray-300 to-green-500"
                      >
                        In comming Audit by Certik
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="sm:w-90 w-full inline-block mt-1 p-px bg-gradient-to-r from-pink-300 to-blue-700 rounded"
            >
              <div
                class="inline-block w-full px-2 py-1 sm:px-4 sm:py-2 border-none rounded bg-gray-900"
              >
                <div class="text-gray-400">
                  <div class="col-span-2 flex flex-col justify-center">
                    <div class="sm:text-lg text-sm font-bold font-sans">
                      ✅
                      <span
                        class="bg-clip-text text-transparent bg-gradient-to-r from-gray-300 to-green-500"
                      >
                        DEX Platform (Phase 1)
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="sm:w-90 w-full inline-block mt-1 p-px bg-gradient-to-r from-blue-700 to-pink-300 rounded"
            >
              <div
                class="inline-block w-full px-2 py-1 sm:px-4 sm:py-2 border-none rounded bg-gray-900"
              >
                <div class="text-gray-400">
                  <div class="col-span-2 flex flex-col justify-center">
                    <div class="sm:text-lg text-sm font-bold font-sans">
                      ✅
                      <span
                        class="bg-clip-text text-transparent bg-gradient-to-r from-gray-300 to-green-500"
                      >
                        DEX Platform (Phase 2)
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="sm:w-90 w-full inline-block mt-1 p-px bg-gradient-to-r from-pink-300 to-blue-700 rounded"
            >
              <div
                class="inline-block w-full px-2 py-1 sm:px-4 sm:py-2 border-none rounded bg-gray-900"
              >
                <div class="text-gray-400">
                  <div class="col-span-2 flex flex-col justify-center">
                    <div class="sm:text-lg text-sm font-bold font-sans">
                      ✅
                      <span
                        class="bg-clip-text text-transparent bg-gradient-to-r from-gray-300 to-green-500"
                      >
                        HBX Stable Coin
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="sm:w-90 w-full inline-block mt-1 p-px bg-gradient-to-r from-yellow-300 to-yellow-500 rounded"
            >
              <div
                class="inline-block w-full px-2 py-1 sm:px-4 sm:py-2 border-none rounded bg-gray-900"
              >
                <div class="text-gray-400">
                  <div class="col-span-2 flex flex-col justify-center">
                    <div class="sm:text-lg text-sm font-bold font-sans">
                      ⬜️
                      <span
                        class="bg-clip-text text-transparent bg-gradient-to-r from-gray-300 to-green-500"
                      >
                        DEX Platform (Phase 3)
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="sm:w-90 w-full inline-block mt-1 p-px bg-gradient-to-r from-yellow-300 to-yellow-500 rounded"
            >
              <div
                class="inline-block w-full px-2 py-1 sm:px-4 sm:py-2 border-none rounded bg-gray-900"
              >
                <div class="text-gray-400">
                  <div class="col-span-2 flex flex-col justify-center">
                    <div class="sm:text-lg text-sm font-bold font-sans">
                      ⬜️
                      <span
                        class="bg-clip-text text-transparent bg-gradient-to-r from-gray-300 to-green-500"
                      >
                        Listing on CEX
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="sm:w-90 w-full inline-block mt-1 p-px bg-gradient-to-r from-yellow-300 to-yellow-500 rounded"
            >
              <div
                class="inline-block w-full px-2 py-1 sm:px-4 sm:py-2 border-none rounded bg-gray-900"
              >
                <div class="text-gray-400">
                  <div class="col-span-2 flex flex-col justify-center">
                    <div class="sm:text-lg text-sm font-bold font-sans">
                      ⬜️
                      <span
                        class="bg-clip-text text-transparent bg-gradient-to-r from-gray-300 to-green-500"
                      >
                        More Marketing
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex">
          <div class="flex flex-col items-center mr-4">
            <div>
              <div
                class="flex items-center justify-center w-10 h-10 border rounded-full"
              >
                <i class="fad fa-check"></i>
              </div>
            </div>
          </div>
          <div class="pt-1">
            <p class="mb-2 text-lg font-bold">Next...</p>
            <p class="text-gray-400"></p>
          </div>
        </div>
      </div>
      <div class="relative">
        <img
          class="inset-0 object-cover object-bottom w-full rounded shadow-lg h-96 lg:absolute lg:h-full"
          src="https://app.svgator.com/assets/svgator.webapp/log-in-girl.svg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
